import React from "react"
import Layout from "../components/layout"
import {FormattedMessage, injectIntl} from "react-intl"

///------------------------------------------------------
import mipt_logo from "../images/partners/mipt_logo.jpg"
import inr_linac from "../images/partners/Linac-OUK_big.gif"
import inr_logo from "../images/partners/inr_logo.png"
import iki_logo from "../images/partners/iki_logo.png"

const PartnersPage = () => {
    return (
        <Layout>
            <div className="media" style={{marginTop: `30px`}}>
                <div className="media-body">
                    <h2 className="partner">
                        <a aria-label="MIPT general physics" href="https://mipt.ru/education/chair/physics/">
                            <FormattedMessage id="partners.mipt.title_fund"/>
                        </a>
                    </h2>
                    <p><FormattedMessage id="partners.mipt.description_fund"/></p>
                    <hr/>
                    <h2 className="partner">
                        <a aria-label="MIPT HEP" href="https://mipt.ru/science/labs/high_energy_physics_lab.php">
                            <FormattedMessage id="partners.mipt.title_energy"/>
                        </a>
                    </h2>
                    <p><FormattedMessage id="partners.mipt.description_energy"/></p>
                </div>
                <div className="media-right media-middle">
                    <a href="https://mipt.ru/education/chair/physics/">
                        <img className="media-object" src={mipt_logo} alt="mipt logo" width="400px"/>
                    </a>
                </div>
            </div>

            <hr/>

            <div className="media">
                <div className="media-body">
                    <h2 className="partner">
                        <a href="http://inr.ru/">
                            <FormattedMessage id="partners.ras.title_exp"/>
                        </a>
                    </h2>
                    <p><FormattedMessage id="partners.ras.description_exp"/></p>
                </div>
            </div>

            <hr/>

            <div className="media">
                <div className="media-left media-middle">
                    <a href="http://www.inr.ru">
                        <img className="media-object" src={inr_linac} alt="inr logo" width="100px"/>
                    </a>
                </div>
                <div className="media-body" style={{marginLeft: `25px`}}>
                    <h2 className="partner">
                        <a aria-label="INR" href="http://www.inr.ru/">
                            <FormattedMessage id="partners.ras.title_beam"/>
                        </a>
                    </h2>
                    <p><FormattedMessage id="partners.ras.description_beam"/></p>
                </div>
            </div>

            <hr/>

            <div className="media">
                <div className="media-body">
                    <h2 className="partner"><a aria-label="INR education" href="http://www.inr.ru/"><FormattedMessage
                        id="partners.ras.title_education"/></a></h2>
                    <p><FormattedMessage id="partners.ras.description_education"/></p>
                </div>
                <div className="media-right media-middle">
                    <a href="http://www.inr.ru"><img className="media-object" src={inr_logo} alt="inr logo"
                                                     width="100px" style={{marginLeft: `10px`}}/></a>
                </div>
            </div>

            <hr/>

            <div className="media" style={{marginBottom: `30px`}}>
                <div className="media-body">
                    <h2 className="partner"><a aria-label="SRI RAS" href="http://www.iki.rssi.ru/"><FormattedMessage
                        id="partners.ras.title_iki"/></a></h2>
                    <p><FormattedMessage id="partners.ras.description_iki"/></p>
                </div>
                <div className="media-right media-middle">
                    <a href="http://www.iki.rssi.ru"><img className="media-object"
                                                          src={iki_logo} alt="iki logo"
                                                          width="100px"/></a>
                </div>
            </div>

        </Layout>
    )
}

export default injectIntl(PartnersPage)